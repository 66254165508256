import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "A trick to make your big dataviz load super fast",
  "description": "",
  "date": "2016-10-17T08:00:00.000Z",
  "published": "2016-10-17T08:00:00.000Z",
  "image": ""
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Big datasets are fun. The bigger, the better, `}<em parentName="p">{`especially`}</em>{` when you let people explore them live in their browser.`}</p>
    <p>{`But there’s a catch: big datasets are slow to load.`}</p>
    <p>{`Even with modern content delivery networks (CDNs), gzip compression, and high internet speeds, it can take a few seconds to load and parse a dataset. In my `}<a parentName="p" {...{
        "href": "https://swizec.github.io/h1b-software-salaries/"
      }}>{`H1B salaries visualization`}</a>{`, downloading data takes 1.7 seconds, parsing takes another 2 seconds, and rendering takes maybe a full second because some things are done stupidly.`}</p>
    <p>{`That’s a full 4 to 5 seconds before a user sees anything more than a `}<em parentName="p">{`"Loading, please wait”`}</em>{` message. Users are going to leave before they play with your dataset. Yes, even though it’s `}<em parentName="p">{`so cool`}</em>{` and the data is amazing and awesome, users don’t give a shit. It’s sad. ☹️`}</p>
    <p>{`But there’s a trick to keep them around → show them an image first!`}</p>
    <p>{`Check this out:`}</p>
    <p><img parentName="p" {...{
        "src": "/b822f9233b835fd2ca335a88061e1f2a/my70wtC.gif",
        "alt": null
      }}></img></p>
    <p>{`See how you barely notice the page refresh? That’s on purpose.`}</p>
    <p>{`The main `}<inlineCode parentName="p">{`App.render()`}</inlineCode>{` method is wrapped in a conditional statement that checks if the data is available. If it is, then we render the interactive visualization; if it isn’t, then we render a screenshot and default descriptions.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// src/App.js

render() {
    if (this.state.techSalaries.length < 1) {
        return (
            <preloader>
        );
    }

    // render the main dataviz
}
</preloader>
`}</code></pre>
    <p>{`The `}<inlineCode parentName="p">{`Preloader`}</inlineCode>{` component can be a functional stateless component, like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// src/App.js

import StaticViz from './preloading.png';

const Preloader = () => (
    <div class="App container">
        <h1>The average H1B in tech pays $86,164/year</h1>
        <p class="lead">Since 2012 the US tech industry has sponsored 176,075 H1B work visas. Most of them paid <b>$60,660 to $111,668</b> per year (1 standard deviation). <span>The best city for an H1B is <b>Kirkland, WA</b> with an average individual salary <b>$39,465 above local household median</b>. Median household salary is a good proxy for cost of living in an area.</span></p>
        <img src={StaticViz} style="{{width:" '100%'}}="">
        <h2 class="text-center">Loading data ...</h2>
    </div>
);
`}</code></pre>
    <p>{`The `}<inlineCode parentName="p">{`Preloader`}</inlineCode>{` component mimics the structure of your normal dataviz, but it’s hardcoded. The information is real, and it’s what people are looking for, but it doesn’t need the dataset to render.`}</p>
    <p>{`The easiest way to get this is to first build your real dataviz, then screenshot the picture, and then copy-paste the descriptions if they’re dynamic. Without dynamic descriptions, half your job is done already.`}</p>
    <p>{`That’s about it, really:`}</p>
    <ol>
      <li parentName="ol">{`render an image`}</li>
      <li parentName="ol">{`wait for data to load`}</li>
      <li parentName="ol">{`replace image with dynamic dataviz`}</li>
    </ol>
    <p>{`It sounds dumb, but increases user satisfaction 341324%.`}</p>
    <p>{`If it works …`}</p>
    <p><img parentName="p" {...{
        "src": "/1eca4eb68956fb196ad012b9c9ae7b16/media-jPAdK8Nfzzwt2-giphy.gif",
        "alt": null
      }}></img></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      